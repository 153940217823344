import React, { useEffect, useState } from 'react';
import MainAreaInfo from './mainAreaInfo';
import 'react-reflex/styles.css';
import { ReflexContainer, ReflexElement, ReflexSplitter } from 'react-reflex';
import { CollapsedSideBar } from './collapsedSideBar';
import BeforeSelectInfo from './BeforeSelectInfo';
import { Button, NoInteractionView } from './reusableComponents';
import {
    faFolder,
    faPieChart,
    faFileDownload,
} from '@fortawesome/free-solid-svg-icons';
import Navbar from './navbar';
import Sidebar from './sidebar';
import { useGlobalCredits } from './GlobalStateContext';
import TransactionList from './Transactionlist';
import { generateUniqueNumber } from '../utils';
import { getUrl } from './pdfDisplay/utils';

const getTableDataSource = (
    extractedDataList,
    email,
    folderName,
    setShowReportModal,
    setWorkOnTemplate,
    handleDownloadClick
) => {
    const dataSource = [];
    extractedDataList.forEach((extractedData, index) => {
        if (!extractedData.show_table) {
            return;
        }
        const data = {
            key: index,
            serialNumber: `${index + 1}.`,
            templateName: extractedData.template,
            timestamp: extractedData.timestamp,
            modelName: extractedData.model_name,
            action: (
                <div className='flex items-center justify-start'>
                    <Button
                        text={'Download Data'}
                        className={'m-0'}
                        hoverText={`Download extracted data from ${extractedData.template} template`}
                        icon={faFileDownload}
                        disabled={false}
                        onClick={() =>
                            handleDownloadClick(
                                email,
                                folderName,
                                extractedData.template
                            )
                        }
                        type={'success'}
                    />

                    <Button
                        text={'View Report'}
                        className={'ml-2'}
                        hoverText={`View report for ${extractedData.template} template`}
                        icon={faPieChart}
                        disabled={false}
                        onClick={() => {
                            setWorkOnTemplate(extractedData.template);
                            setShowReportModal(true);
                        }}
                        type={'success'}
                    />
                </div>
            ),
        };

        dataSource.push(data);
    });

    return dataSource;
};

const Main_info = ({
    activeTemplate,
    setActiveTemplate,
    templateList,
    setTemplateList,
    fetchTemplateList,
    isAdmin,
    setIsAdmin,
    newNotification,
    setNewNotification,
    viewNotifications,
    setViewNotifications,
    messages,
    socket,
    processingRepos,
    isSideViewExpanded,
    setIsSideViewExpanded,
    setOpenWithTemplateDesigner,
}) => {
    const user = JSON.parse(localStorage.getItem('current'));
    const [repoToExtract, setRepoToExtract] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [fileToChat, setFileToChat] = useState('');
    const [fine_tuned_chat, set_fine_tuned_chat] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const { transactionMode, setTransactionMode } = useGlobalCredits();
    const [showReportModal, setShowReportModal] = useState(false);
    const [workOnTemplate, setWorkOnTemplate] = useState(null);
    const [downloadList, setDownloadList] = useState([]);

    const handleDownloadClick = async (email, folderName, templateName) => {
        try {
            const uniqueNumber = generateUniqueNumber();

            const response = await fetch(
                getUrl(
                    `http://${process.env.REACT_APP_FAST_API_HOST}:${
                        process.env.REACT_APP_FAST_API_PORT
                    }/redownloadfile_excell?email=${email}&foldername=${folderName}&uid=${toString(
                        uniqueNumber
                    )}&templatename=${templateName}`
                )
            );
            const disposition = response.headers.get('Content-Disposition');

            if (disposition && disposition.includes('attachment')) {
                const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                const matches = fileNameRegex.exec(disposition);
                let filename =
                    matches != null && matches[1] ? matches[1] : 'file.xlsx';
                const originalString = filename;
                const modifiedString = originalString.substring(
                    1,
                    originalString.length - 1
                );

                filename = modifiedString;
                const blob = await response.blob();
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = filename;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            } else {
                const data = await response.json();
                alert(data.message);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 1024); // Change 1024 to your desired breakpoint
        };

        handleResize(); // Initial check
        window.addEventListener('resize', handleResize);

        (async () => {
            setIsLoading(true);
            await fetchTemplateList(user, setActiveTemplate, setTemplateList);
            setIsLoading(false);
        })();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const fetchDownloadList = async (repoName, repoId, email) => {
        const folderName = repoName;
        const repoid = repoId;

        //Fetching extracted data list.
        const extractResponse = await fetch(
            getUrl(
                `http://${process.env.REACT_APP_FAST_API_HOST}:${process.env.REACT_APP_FAST_API_PORT}/getExtractedTemplates?email=${email}&repoid=${repoid}`
            )
        );
        const data = await extractResponse.json();
        const extractedList = data.rows;

        if (folderName && email) {
            const modifiedExtractedList = getTableDataSource(
                extractedList,
                email,
                folderName,
                setShowReportModal,
                setWorkOnTemplate,
                handleDownloadClick
            );
            setDownloadList(modifiedExtractedList);
        }
    };

    if (isLoading) {
        return <NoInteractionView viewText={'Loading ...'} />;
    }
    const updateRepoToExtract = (value) => {
        if (value.name === 'ALL') {
            alert('Extraction is not supported for this repository');
            value = null;
        }
        setRepoToExtract(value);
    };

    return (
        <div className='z-0 w-[99vw] h-[98vh] m-auto my-2'>
            <ReflexContainer orientation='vertical'>
                {!isMobile && (
                    <ReflexElement
                        className='left-pane mr-0.5'
                        minSize={isSideViewExpanded ? 250 : 50}
                        size={isSideViewExpanded ? 350 : 50}
                        maxSize={isSideViewExpanded ? 700 : 50}
                    >
                        <div className='pane-content w-full h-full bg-transparent'>
                            {isSideViewExpanded ? (
                                <div className='w-full h-full border border-2 overflow-auto bg-backgrounds-slate rounded-xl p-2 z-10'>
                                    <Sidebar
                                        chatrepo={repoToExtract}
                                        chatrepofunction={updateRepoToExtract}
                                        set_fine_tuned={set_fine_tuned_chat}
                                        fine_tune={fine_tuned_chat}
                                        fileToChat={fileToChat}
                                        setFileToChat={setFileToChat}
                                        setIsRepoViewExpanded={
                                            setIsSideViewExpanded
                                        }
                                        templateList={templateList}
                                        fetchDownloadList={fetchDownloadList}
                                        isAdmin={isAdmin}
                                        setIsAdmin={setIsAdmin}
                                        mode={'info'}
                                        socket={socket}
                                        processingRepos={processingRepos}
                                        setViewNotifications={
                                            setViewNotifications
                                        }
                                    />
                                </div>
                            ) : (
                                <CollapsedSideBar
                                    icon={faFolder}
                                    iconColor={'icons-primary'}
                                    title={'Repositories'}
                                    setIsRepoViewExpanded={
                                        setIsSideViewExpanded
                                    }
                                />
                            )}
                        </div>
                    </ReflexElement>
                )}
                <ReflexSplitter
                    style={{
                        border: 0,
                        background: 'white',
                        width: '6px',
                        zIndex: 10,
                        height: '98vh',
                    }}
                />

                <ReflexElement className='right-pane ml-0.5'>
                    <div className='pane-content w-full h-full bg-transparent'>
                        <div className='w-full h-full border border-2 overflow-auto bg-backgrounds-slate rounded-xl z-0'>
                            <ReflexContainer orientation='horizontal'>
                                <ReflexElement
                                    className='header'
                                    style={{
                                        minHeight: '4rem',
                                        height: '4rem',
                                        maxHeight: '4rem',
                                    }}
                                >
                                    <div className='pane-content w-full h-full p-2'>
                                        <Navbar
                                            chatrepo={repoToExtract}
                                            chatrepofunction={
                                                updateRepoToExtract
                                            }
                                            set_fine_tuned={set_fine_tuned_chat}
                                            fine_tune={fine_tuned_chat}
                                            fileToChat={fileToChat}
                                            setFileToChat={setFileToChat}
                                            setIsRepoViewExpanded={
                                                setIsSideViewExpanded
                                            }
                                            isAdmin={isAdmin}
                                            setIsAdmin={setIsAdmin}
                                            mode={'info'}
                                            newNotification={newNotification}
                                            setNewNotification={
                                                setNewNotification
                                            }
                                            viewNotifications={
                                                viewNotifications
                                            }
                                            setViewNotifications={
                                                setViewNotifications
                                            }
                                            messages={messages}
                                            socket={socket}
                                            processingRepos={processingRepos}
                                        />
                                    </div>
                                </ReflexElement>

                                <ReflexElement flex={1}>
                                    <div className='pane-content h-full w-full p-2'>
                                        {transactionMode ? (
                                            <>
                                                <TransactionList />
                                            </>
                                        ) : (
                                            <>
                                                {repoToExtract === null ? (
                                                    <BeforeSelectInfo />
                                                ) : (
                                                    <MainAreaInfo
                                                        workOnRepo={
                                                            repoToExtract
                                                        }
                                                        templateList={
                                                            templateList
                                                        }
                                                        activeTemplate={
                                                            activeTemplate
                                                        }
                                                        fetchDownloadList={
                                                            fetchDownloadList
                                                        }
                                                        setDownloadList={
                                                            setDownloadList
                                                        }
                                                        showReportModal={
                                                            showReportModal
                                                        }
                                                        workOnTemplate={
                                                            workOnTemplate
                                                        }
                                                        downloadList={
                                                            downloadList
                                                        }
                                                        setShowReportModal={
                                                            setShowReportModal
                                                        }
                                                        setWorkOnTemplate={
                                                            setWorkOnTemplate
                                                        }
                                                        setActiveTemplate={
                                                            setActiveTemplate
                                                        }
                                                        socket={socket}
                                                        processingRepos={
                                                            processingRepos
                                                        }
                                                        setViewNotifications={
                                                            setViewNotifications
                                                        }
                                                        setOpenWithTemplateDesigner={
                                                            setOpenWithTemplateDesigner
                                                        }
                                                    />
                                                )}
                                            </>
                                        )}
                                    </div>
                                </ReflexElement>
                            </ReflexContainer>
                        </div>
                    </div>
                </ReflexElement>
            </ReflexContainer>
        </div>
    );
};

export default Main_info;
