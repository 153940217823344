import React from 'react';

import Layout from './Layout.jsx';
import Functionalities from './Functionalities.jsx';
import Purchases from './Purchases.jsx';
import Footer from './Footer.jsx';
import ChatSteps from './ChatSteps.jsx';
import Navbar from './Navbar.jsx';
import { useMenuItems } from './menuItems.jsx';

const LandingPage = () => {
    const {
        menuItems,
        layoutRef,
        functionalitiesRef,
        purchasesRef,
        footerRef,
        chatStepsRef,
    } = useMenuItems();

    return (
        <div>
            <Navbar menuItems={menuItems} />
            <Layout ref={layoutRef} />
            <Functionalities ref={functionalitiesRef} />
            <ChatSteps ref={chatStepsRef} />
            <Purchases ref={purchasesRef} />
            <Footer ref={footerRef} />
        </div>
    );
};

export default LandingPage;
