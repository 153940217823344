import './styles';
import Main from './components/Main';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Login from './components/login';
import Signup from './components/signup';
import ForgotPassword from './components/ForgotPassword';
import ConfirmationPage from './components/ConfirmationPage';
import ChangePassword from './components/ChangePassword';
import Temp from './test/temp';
import MainInfo from './components/mainInfo';
import { Support, ContactUs } from './components/support';
import { useEffect, useState } from 'react';
import { SettingsView } from './components/settings';
import axios from 'axios';
import VerifyEmailBlockerPage from './components/verifyEmailBlockerPage';
import { useAuth } from './context/AuthContext';
import VerifyEmail from './components/verifyEmail';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { ReportLayoutsView } from './components/reportLayouts';
import { getUrl } from './components/pdfDisplay/utils.js';
import io from 'socket.io-client';
import { AuthStatus } from './constants/authStatus';
import { TaskProgressType } from './constants/taskProgressTypes';
import LandingPage from './landingpage/src/landingPage.jsx';
import TermsAndConditions from './landingpage/src/FooterPages/TermsOfUse.jsx';
import BrandGuidelines from './landingpage/src/FooterPages/BrandGuidelines.jsx';
import PrivacyPolicy from './landingpage/src/FooterPages/PrivacyPolicy.jsx';
import RefundsAndCancellations from './landingpage/src/FooterPages/RefundsAndCancellations.jsx';

const SOCKET_SERVER_URL = getUrl(
    `http://${process.env.REACT_APP_SOCKET_MANAGER_HOST}:${process.env.REACT_APP_SOCKET_MANAGER_PORT}`
);

const fetchTemplateList = async (user, setActiveTemplate, setTemplateList) => {
    // Fetching selected template
    try {
        if (!user) {
            throw new Error('User not found!');
        }
        const dynamicUrl = getUrl(
            `http://${process.env.REACT_APP_FAST_API_HOST}:${process.env.REACT_APP_FAST_API_PORT}/get_template_by_email?email=${user.email}`
        );
        const selectedTemplateResponse = await fetch(dynamicUrl);
        const selectedTemplate = await selectedTemplateResponse.json();
        //${process.env.REACT_APP_NODE_SERVER_HOST}:${process.env.REACT_APP_NODE_SERVER_PORT}
        // Fetching template list
        const tempUrl = getUrl(
            `http://${process.env.REACT_APP_NODE_SERVER_HOST}:${process.env.REACT_APP_NODE_SERVER_PORT}/gettemplate`
        );
        const response = await axios.get(tempUrl, {
            params: {
                id: user.email,
            },
        });

        const templatesData = response.data;

        // Find and set default template
        // if selected template is available, then set it as active template
        // else set first available default template as active template
        if (selectedTemplate.template_name) {
            templatesData.forEach((data) => {
                if (
                    data.filename &&
                    selectedTemplate.template_name &&
                    data.filename === selectedTemplate.template_name
                ) {
                    setActiveTemplate(data);
                }
            });
        } else {
            // if no selected template , then set first available default temp

            const defaultTemplates = templatesData.filter((template) =>
                template.filename.toLowerCase().startsWith('default_')
            );

            if (defaultTemplates && defaultTemplates.length) {
                setActiveTemplate(defaultTemplates[0]);
            }
        }

        setTemplateList(templatesData);
    } catch (error) {
        alert(error.message);
    }
};

const getOnGoingProcesses = async (user) => {
    try {
        const dynamicUrl = getUrl(
            `http://${process.env.REACT_APP_NODE_SERVER_HOST}:${process.env.REACT_APP_NODE_SERVER_PORT}/getOnGoingProcesses?email=${user.email}`
        );

        const encodedToken = encodeURIComponent(user.token);
        const response = await axios.get(dynamicUrl, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: encodedToken,
            },
        });

        if (response) {
            const onGoingProcesses = response.data.onGoingProcesses;
            return onGoingProcesses ? onGoingProcesses : [];
        }
        return [];
    } catch (error) {
        alert(error.message);
        return [];
    }
};

const createId = (message) => {
    const userMail = message.userMail ? message.userMail : message.user_mail;
    const repoName = message.repoName ? message.repoName : message.repo_name;
    const process = message.process;
    const id = `${userMail}-${repoName}-${process}`;
    return id;
};

const formatMessage = (messageData) => {
    return {
        id: messageData.id,
        message: `Processing ${messageData.process} on ${messageData.repo_name}`,
        process: messageData.process,
        progress: messageData.progress,
        repoId: messageData.repoId,
        repoName: messageData.repo_name,
        timeStamp: messageData.timestamp,
        userMail: messageData.user_mail,
    };
};

const isValidNotification = async (
    notification,
    notificationMap,
    user,
    setProcessingRepos
) => {
    const id = createId(notification);

    if (notification.progress === TaskProgressType.PROCESSING) {
        if (notificationMap.has(id)) {
            notificationMap.set(id, notification);
            return true;
        } else {
            // If start message is missed some how updated data is checked and entry is made accordingly
            const onGoingProcesses = await getOnGoingProcesses(user);
            setProcessingRepos(onGoingProcesses);

            const found = onGoingProcesses.find(
                (process) => id === createId(process)
            );
            if (found) {
                notificationMap.set(id, notification);
                return true;
            }
        }
    } else if (notification.progress === TaskProgressType.STARTED) {
        notificationMap.set(id, notification);
        return true;
    } else {
        if (notificationMap.has(id)) {
            notificationMap.delete(id);
        }
        // End message is valid and should be sent to update state.
        return true;
    }

    return false;
};

const App = () => {
    const [activeTemplate, setActiveTemplate] = useState(null);
    const [templateList, setTemplateList] = useState([]);
    const [isAdmin, setIsAdmin] = useState(false);
    const { authenticated } = useAuth();
    const [newNotification, setNewNotification] = useState(null);
    const [viewNotifications, setViewNotifications] = useState(false);
    const [messages, setMessages] = useState([]);
    const [socket, setSocket] = useState(null);
    const [processingRepos, setProcessingRepos] = useState([]);
    const [displayFloatingButton, setDisplayFloatingButton] = useState(true);
    const [notificationMap, setNotificationMap] = useState(new Map());
    const [isSideViewExpanded, setIsSideViewExpanded] = useState(true);
    const user = JSON.parse(localStorage.getItem('current'));
    const [openWithTemplateDesigner, setOpenWithTemplateDesigner] =
        useState(false);

    useEffect(() => {
        // Create a WebSocket connection
        const newSocket = io(SOCKET_SERVER_URL);
        setSocket(newSocket);

        // Listen for incoming messages
        newSocket.on('register', () => {
            if (authenticated === AuthStatus.AUTHENTICATED) {
                // Register the client to socket manager
                newSocket.emit('registerClient', user.email);

                // On 'registration' complete
                newSocket.on('registrationComplete', (socketId) => {
                    console.log(
                        'Registration with socket manager complete! Socket Id: ',
                        socketId
                    );
                });

                // Listen for incoming messages
                newSocket.on('statusUpdate', async (notification) => {
                    const isValid = await isValidNotification(
                        notification,
                        notificationMap,
                        user,
                        setProcessingRepos
                    );
                    if (isValid) {
                        setNewNotification(notification);
                        setMessages((prevMessages) => [
                            ...prevMessages,
                            notification,
                        ]);
                    }
                });
            }
        });

        // Clean up the socket connection when the component unmounts
        return () => newSocket.close();
    }, [authenticated]);

    useEffect(() => {
        (async () => {
            if (authenticated === AuthStatus.AUTHENTICATED) {
                // Getting processing list
                const onGoingProcesses = await getOnGoingProcesses(user);
                setProcessingRepos(onGoingProcesses);

                if (
                    onGoingProcesses &&
                    onGoingProcesses.length === 0 &&
                    messages.length
                ) {
                    setMessages([]);
                } else {
                    // Adding notification to notificationMap to make related notification valid
                    if (onGoingProcesses.length) {
                        onGoingProcesses.forEach((process) => {
                            const modifiedData = formatMessage(process);
                            const id = createId(modifiedData);
                            notificationMap.set(id, modifiedData);
                        });
                    }

                    const newMessages = [...onGoingProcesses];
                    const modifiedMessages = newMessages.map((messageData) => {
                        return formatMessage(messageData);
                    });
                    modifiedMessages.sort((a, b) =>
                        a['repoName'].localeCompare(b['repoName'])
                    );
                    setMessages(modifiedMessages);
                }
            }
        })();

        return () => {};
    }, [authenticated]);

    useEffect(() => {
        if (
            newNotification &&
            newNotification.progress !== TaskProgressType.PROCESSING
        ) {
            setViewNotifications(true);
        }
        return () => {};
    }, [messages]);

    useEffect(() => {
        const messagesMap = new Map();
        messages.forEach((notification) => {
            const key = createId(notification);
            messagesMap.set(key, notification);
        });

        const reposProgressData = [...messagesMap.values()];
        let updatedProcessingRepos = [...processingRepos];

        // Section to update processing data state
        reposProgressData.forEach((progressData) => {
            const notificationId = createId(progressData);
            if (progressData.progress === TaskProgressType.PROCESSING) {
                const found = updatedProcessingRepos.find(
                    (task) => createId(task) === notificationId
                );
                if (!found) {
                    // Add to processing state
                    updatedProcessingRepos.push(progressData);
                }
            } else {
                const found = updatedProcessingRepos.find(
                    (task) => createId(task) === notificationId
                );
                if (found) {
                    const foundId = createId(found);
                    // Remove from processing sate
                    updatedProcessingRepos = updatedProcessingRepos.filter(
                        (task) => createId(task) !== foundId
                    );
                }
            }
        });

        setProcessingRepos(updatedProcessingRepos);

        return () => {};
    }, [messages]);

    return (
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_LOGIN_KEY}>
            <BrowserRouter>
                <Routes>
                    {/* Landing Page route */}
                    <Route path='/' element={<LandingPage />} />
                    <Route
                        path='/terms-and-conditions'
                        element={<TermsAndConditions />}
                    />
                    <Route path='/privacy-policy' element={<PrivacyPolicy />} />
                    <Route
                        path='/brand-guidelines'
                        element={<BrandGuidelines />}
                    />
                    <Route
                        path='/refund-and-cancellations'
                        element={<RefundsAndCancellations />}
                    />
                    <Route path='/contactUs' element={<ContactUs />} />
                    {authenticated === AuthStatus.AUTHENTICATED ? (
                        <>
                            <Route
                                path='/chats'
                                element={
                                    <Main
                                        isAdmin={isAdmin}
                                        setIsAdmin={setIsAdmin}
                                        fetchTemplateList={fetchTemplateList}
                                        setTemplateList={setTemplateList}
                                        setActiveTemplate={setActiveTemplate}
                                        newNotification={newNotification}
                                        setNewNotification={setNewNotification}
                                        viewNotifications={viewNotifications}
                                        setViewNotifications={
                                            setViewNotifications
                                        }
                                        templateList={templateList}
                                        messages={messages}
                                        socket={socket}
                                        processingRepos={processingRepos}
                                        isSideViewExpanded={isSideViewExpanded}
                                        setIsSideViewExpanded={
                                            setIsSideViewExpanded
                                        }
                                    />
                                }
                            />
                            <Route
                                path='/extraction'
                                element={
                                    <MainInfo
                                        activeTemplate={activeTemplate}
                                        setActiveTemplate={setActiveTemplate}
                                        templateList={templateList}
                                        setTemplateList={setTemplateList}
                                        fetchTemplateList={fetchTemplateList}
                                        isAdmin={isAdmin}
                                        setIsAdmin={setIsAdmin}
                                        newNotification={newNotification}
                                        setNewNotification={setNewNotification}
                                        viewNotifications={viewNotifications}
                                        setViewNotifications={
                                            setViewNotifications
                                        }
                                        messages={messages}
                                        socket={socket}
                                        processingRepos={processingRepos}
                                        isSideViewExpanded={isSideViewExpanded}
                                        setIsSideViewExpanded={
                                            setIsSideViewExpanded
                                        }
                                        setOpenWithTemplateDesigner={
                                            setOpenWithTemplateDesigner
                                        }
                                    />
                                }
                            />
                            <Route
                                path='/templates-and-layouts'
                                element={
                                    <ReportLayoutsView
                                        activeTemplate={activeTemplate}
                                        setActiveTemplate={setActiveTemplate}
                                        templateList={templateList}
                                        setTemplateList={setTemplateList}
                                        fetchTemplateList={fetchTemplateList}
                                        newNotification={newNotification}
                                        setNewNotification={setNewNotification}
                                        viewNotifications={viewNotifications}
                                        setViewNotifications={
                                            setViewNotifications
                                        }
                                        messages={messages}
                                        socket={socket}
                                        isSideViewExpanded={isSideViewExpanded}
                                        setIsSideViewExpanded={
                                            setIsSideViewExpanded
                                        }
                                        openWithTemplateDesigner={
                                            openWithTemplateDesigner
                                        }
                                        setOpenWithTemplateDesigner={
                                            setOpenWithTemplateDesigner
                                        }
                                    />
                                }
                            />
                            <Route
                                path='/settings'
                                element={
                                    <SettingsView
                                        activeTemplate={activeTemplate}
                                        setActiveTemplate={setActiveTemplate}
                                        templateList={templateList}
                                        setTemplateList={setTemplateList}
                                        fetchTemplateList={fetchTemplateList}
                                        newNotification={newNotification}
                                        setNewNotification={setNewNotification}
                                        viewNotifications={viewNotifications}
                                        setViewNotifications={
                                            setViewNotifications
                                        }
                                        messages={messages}
                                        socket={socket}
                                        isAdmin={isAdmin}
                                        isSideViewExpanded={isSideViewExpanded}
                                        setIsSideViewExpanded={
                                            setIsSideViewExpanded
                                        }
                                    />
                                }
                            />
                            <Route
                                path='/verify-email'
                                element={<VerifyEmail />}
                            />
                            <Route
                                path='/support'
                                element={
                                    <Support
                                        newNotification={newNotification}
                                        setNewNotification={setNewNotification}
                                        viewNotifications={viewNotifications}
                                        setViewNotifications={
                                            setViewNotifications
                                        }
                                        messages={messages}
                                        socket={socket}
                                        isSideViewExpanded={isSideViewExpanded}
                                        setIsSideViewExpanded={
                                            setIsSideViewExpanded
                                        }
                                        displayFloatingButton={
                                            displayFloatingButton
                                        }
                                        setDisplayFloatingButton={
                                            setDisplayFloatingButton
                                        }
                                    />
                                }
                            />

                            <Route path='/test' element={<Temp />} />
                            <Route
                                path='/forgot-password/reset'
                                element={<ChangePassword />}
                            />
                            <Route
                                path='*'
                                element={
                                    <Navigate
                                        to='/chats'
                                        element={
                                            <Main
                                                isAdmin={isAdmin}
                                                setIsAdmin={setIsAdmin}
                                            />
                                        }
                                    />
                                }
                            />
                        </>
                    ) : (
                        <>
                            {authenticated ===
                            AuthStatus.REQUIRE_MAIL_VERIFICATION ? (
                                <>
                                    <Route
                                        path='/verify-email-blocker'
                                        element={<VerifyEmailBlockerPage />}
                                    />
                                    <Route
                                        path='/verify-email'
                                        element={<VerifyEmail />}
                                    />
                                    <Route
                                        path='/forgot-password/reset'
                                        element={<ChangePassword />}
                                    />
                                    <Route
                                        path='*'
                                        element={
                                            <Navigate to='/verify-email-blocker' />
                                        }
                                    />
                                </>
                            ) : (
                                <>
                                    <Route
                                        path='/verify-email'
                                        element={<VerifyEmail />}
                                    />
                                    <Route
                                        path='/forgot-password'
                                        element={<ForgotPassword />}
                                    />
                                    <Route
                                        path='/forgot-password/reset'
                                        element={<ChangePassword />}
                                    />
                                    <Route
                                        path='/confirmation'
                                        element={<ConfirmationPage />}
                                    />
                                    <Route path='/login' element={<Login />} />
                                    <Route
                                        path='/signup'
                                        element={<Signup />}
                                    />
                                    <Route
                                        path='*'
                                        element={<Navigate to='/login' />}
                                    />
                                </>
                            )}
                        </>
                    )}
                </Routes>
            </BrowserRouter>
        </GoogleOAuthProvider>
    );
};

export default App;
