import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useLocation } from 'react-router';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ExtractData } from './ExtractData';
import {
    faFolderPlus,
    faFolderOpen,
    faFolderClosed,
    faFileCirclePlus,
    faTrash,
    faFolder,
    faCaretUp,
    faCaretDown,
    faCaretLeft,
    faShare,
    faEllipsisV,
    faChartLine,
    faDownload,
    faSync,
    faDatabase,
    faG,
} from '@fortawesome/free-solid-svg-icons';

import 'react-reflex/styles.css';
import { ReflexContainer, ReflexElement } from 'react-reflex';

import AddNewRepo from './addNewRepo';
import AddFile from './addFile';
import {
    Button,
    ConfirmModal,
    Dropdown,
    LoadingView,
    Modal,
} from './reusableComponents';
import { Brand } from './brand';
import { UserDetailAndAction } from './userDetailAndAction';
import { ShareRepository } from './shareRepository/shareRepository';
import { UserAccessOptions } from '../constants/userAccessOptions';
import { RepositoryStats } from './repositoryStats';
import { RepoStatsDB } from './RepoStatsDB';
import { developerMode } from '../Constants';
import { FilesListView } from './filesListView';
import { Mode } from '../constants/modeTypes';
import { RepoType } from '../constants/repoType';
import { useGlobalCredits } from './GlobalStateContext';
import { MODE } from '../enums';
import { getUrl } from './pdfDisplay/utils';
const FileDownload = require('js-file-download');

const Sidebar = (props) => {
    const user = JSON.parse(localStorage.getItem('current'));

    const {
        chatrepofunction,
        set_fine_tuned,
        fileToChat,
        setFileToChat,
        isAdmin,
        setIsAdmin,
        mode,
        togglePdfVisibility,
        templateList,
        fetchDownloadList,
    } = props;

    const [addNewMode, setAddNewMode] = useState(false);

    const [repoDetails, setRepoDetails] = useState([]);
    const [updateCloudRepo, setUpdateCloudRepo] = useState(false);
    const [workOnRepo, setWorkOnRepo] = useState(-1);
    const [addFileMode, setAddFileMode] = useState(false);
    const [deleteMode, setDeleteMode] = useState(false);
    const [toDelete, setToDelete] = useState({});
    const [toShare, setToShare] = useState(null);
    const [sharedRepos, setSharedRepos] = useState([]);
    const [globalRepos, setGlobalRepos] = useState([]);
    const [viewSharingModal, setViewSharingModal] = useState(false);
    const [toViewStats, setToViewStatus] = useState(null);
    const [viewStatsModal, setViewStatsModal] = useState(false);
    const [viewRepodb, setViewRepodb] = useState(false);
    const [addFileToRepo, setAddFileToRepo] = useState(null);
    const [cloudInfo, setCloudInfo] = useState(null);
    const [finetuning_verify, set_finetuning_verify] = useState(false);
    const [chat_selected, set_chat_selected] = useState(false);
    const [chatting_with_id, set_chatting_with_id] = useState('');
    const [allReposFilesData, setAllReposFilesData] = useState(new Map());
    const [isLoading, setIsLoading] = useState(false);
    const [loadingText, setLoadingText] = useState('');
    const { credits, setCredits } = useGlobalCredits();
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const { transactionMode, setTransactionMode } = useGlobalCredits();
    const [closeDropdownSignal, setCloseDropdownSignal] = useState(false);
    const [showSelectTemplateModal, setShowSelectTemplateModal] =
        useState(false);
    const [selectedRepoForTemplate, setSelectedRepoForTemplate] =
        useState(null);
    const [currentTemplate, setCurrentTemplate] = useState(null);
    const [showReRunExtractionModal, setShowReRunExtractionModal] =
        useState(false);
    const [selectedRepoForReRun, setSelectedRepoForReRun] = useState(null);

    const BREAKPOINT_WIDTH = 1024;
    const location = useLocation();
    const path = location.pathname;

    const timeout = setTimeout(() => {
        setCloseDropdownSignal(false);
    }, 500);

    const toggleDropdownSignal = () => {
        clearTimeout(timeout);
        setCloseDropdownSignal(true);
        setTimeout(timeout);
    };

    useEffect(() => {
        const authToken = encodeURIComponent(user.token);
        const headers = {
            Authorization: authToken,
            'Content-Type': 'application/json',
        };

        // Make GET request to fetch user credits
        axios
            .get(
                getUrl(
                    `http://${process.env.REACT_APP_NODE_SERVER_HOST}:${process.env.REACT_APP_NODE_SERVER_PORT}/getCredits`
                ),
                {
                    headers: headers,
                }
            )
            .then((response) => {
                setCredits(response.data.credits);
            })
            .catch((error) => {
                console.error('Error fetching user credits:', error);
            });
    }, [addNewMode]);

    const getAdminList = async () => {
        try {
            setIsLoading(true);
            setLoadingText(`Loading...`);

            const user = JSON.parse(localStorage.getItem('current'));
            const encodedToken = encodeURIComponent(user.token);
            const response = await fetch(
                getUrl(
                    `http://${process.env.REACT_APP_NODE_SERVER_HOST}:${process.env.REACT_APP_NODE_SERVER_PORT}/AdminList`
                ),
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: encodedToken,
                    },
                }
            );

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            //console.log('response is', response);

            const result = await response.text();
            // console.log('result is', result);
            const isAdminCheck = result.includes(user.email);
            setIsAdmin(isAdminCheck);
        } catch (error) {
            setIsLoading(false);
            setLoadingText('');
            alert(error.message);
        }
    };

    const getAllReposData = async () => {
        try {
            setIsLoading(true);
            setLoadingText(`Loading Repositories ...`);

            const dynamicUrl = getUrl(
                `http://${process.env.REACT_APP_NODE_SERVER_HOST}:${process.env.REACT_APP_NODE_SERVER_PORT}/getAllChatsRepos/${user.email}`
            );
            const encodedToken = encodeURIComponent(user.token);
            const response = await fetch(dynamicUrl, {
                method: 'GET', // or 'POST' or other HTTP method
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${encodedToken}`,
                },
            });

            const data = await response.json();

            const globalRepos = data.Normal.filter(
                (item) => item.repotype === RepoType.GLOBAL
            );

            const normalRepos = data.Normal.filter(
                (item) => item.repotype !== RepoType.GLOBAL
            );

            setGlobalRepos(globalRepos);
            setRepoDetails(normalRepos);
            setSharedRepos(data.Shared);

            setIsLoading(false);
            setLoadingText('');
        } catch (error) {
            setIsLoading(false);
            setLoadingText('');
            alert(error);
        }
    };

    const downloadSchemaFile = async (item) => {
        try {
            const response = await axios.get(
                getUrl(
                    `http://${process.env.REACT_APP_FAST_API_HOST}:${process.env.REACT_APP_FAST_API_PORT}/process_schema_postgress/${item.email}/${item.name}`
                ),
                {
                    responseType: 'blob',
                }
            );
            FileDownload(response.data, `${item.name}_schema.txt`);
        } catch (error) {
            console.error('Error downloading the file', error);
        }
    };

    const handleUpdateSchema = async (item) => {
        try {
            const email = item.email;
            const reponame = item.name;
            const response = await axios.post(
                getUrl(
                    `http://${process.env.REACT_APP_FAST_API_HOST}:${process.env.REACT_APP_FAST_API_PORT}/update-schema_postgress`
                ),
                {
                    email,
                    reponame,
                }
            );
            alert(response.data.message);
        } catch (error) {
            if (
                error.response &&
                error.response.data &&
                error.response.data.detail
            ) {
                alert(error.response.data.detail);
            } else {
                alert('An unexpected error occurred');
            }
        }
    };

    const findIfExpanded = (repoId) => {
        return (
            allReposFilesData &&
            allReposFilesData.has(repoId) &&
            allReposFilesData.get(repoId).isExpanded
        );
    };

    useEffect(() => {
        const repoId =
            selectedRepoForReRun?.repoid || selectedRepoForTemplate?.repoid;
        if (repoId) {
            fetchCurrentTemplate(repoId);
        }
    }, [selectedRepoForReRun, selectedRepoForTemplate]);

    useEffect(() => {
        (async () => {
            await getAdminList();
            await getAllReposData();
        })();
    }, []);

    const handleDebuggingFile = async (item) => {
        try {
            setIsLoading(true);
            setLoadingText(`Downloading Diagnostics File ...`);

            const encodedToken = encodeURIComponent(user.token);
            const email = item.email;
            const foldername = item.name;
            const mode = 'qna';
            const response = await fetch(
                getUrl(
                    `http://${process.env.REACT_APP_NODE_SERVER_HOST}:${process.env.REACT_APP_NODE_SERVER_PORT}/debuggingFile`
                ),
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: encodedToken,
                    },
                    body: JSON.stringify({ email, foldername, mode }),
                }
            );

            if (response.ok) {
                const csvContent = await response.text();

                // Create a Blob containing the CSV data
                const blob = new Blob([csvContent], { type: 'text/csv' });

                // Create an anchor element and set its href attribute to a data URL
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);

                // Specify the filename for the download
                link.download = 'Details.csv';

                // Append the anchor to the document body and trigger a click
                document.body.appendChild(link);
                link.click();

                // Remove the anchor from the document body
                document.body.removeChild(link);

                setIsLoading(false);
                setLoadingText('');
            } else {
                setIsLoading(false);
                setLoadingText('');

                alert(`Error downloading CSV. ${await response.text()}`);
                // console.error(
                //     'Error downloading CSV:',
                //     response.status,
                //     response.statusText
                // );
            }
        } catch (error) {
            setIsLoading(false);
            setLoadingText('');
            alert('Error: ', error.message);
        }
    };

    const updateCloud = useCallback(
        async (item) => {
            try {
                setIsLoading(true);
                setLoadingText(`Updating Repository Data ...`);

                const encodedToken = encodeURIComponent(user.token);
                const email = item.email;
                const repo_name = item.name;

                const response = await fetch(
                    getUrl(
                        `http://${process.env.REACT_APP_FAST_API_HOST}:${process.env.REACT_APP_FAST_API_PORT}/update_repository`
                    ),
                    {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: encodedToken,
                        },
                        body: JSON.stringify({ email, repo_name }),
                    }
                );

                if (response.ok) {
                    //const data = await response.json(); // Assuming the response is JSON

                    setIsLoading(false);
                    setLoadingText('');
                    //alert(`Repository data successfully updated: ${data.message}`);
                } else {
                    setIsLoading(false);
                    setLoadingText('');
                    alert(
                        `Error updating repository data. ${await response.text()}`
                    );
                }
            } catch (error) {
                setIsLoading(false);
                setLoadingText('');
                alert('Error: ', error.message);
            }
        },
        [user.token]
    );

    const finalizeDelete = async () => {
        try {
            setDeleteMode(false);
            setIsLoading(true);
            setLoadingText(`Deleting ${toDelete.name} ...`);

            const encodedToken = encodeURIComponent(user.token);
            const dynamicUrl = getUrl(
                `http://${process.env.REACT_APP_FAST_API_HOST}:${process.env.REACT_APP_FAST_API_PORT}/new_delete`
            );

            // Create a FormData object and append the repoid
            const formData = new FormData();
            formData.append('repoid', toDelete.repoid);

            const response = await fetch(dynamicUrl, {
                method: 'POST',
                headers: {
                    Authorization: encodedToken,
                },
                body: formData,
            });

            if (response.ok) {
                console.log('Deletion successful');
            } else {
                console.error('Deletion failed');
            }

            setIsLoading(false);
            setLoadingText('');
            if (workOnRepo === toDelete.repoid) {
                if (mode === MODE.QNA) {
                    chatrepofunction(-1);
                } else {
                    chatrepofunction(null);
                }
            }
            if (window.innerWidth < BREAKPOINT_WIDTH) {
                if (mode === MODE.QNA) {
                    chatrepofunction(-1);
                } else {
                    chatrepofunction(null);
                }
            }
            await getAllReposData();
        } catch (error) {
            setIsLoading(false);
            setLoadingText('');
            alert('Error: ', error.message);
        }
    };

    const updateMap = (key, value) => {
        const deepCopyMap = new Map(
            JSON.parse(JSON.stringify(Array.from(allReposFilesData)))
        );
        deepCopyMap.set(key, value);
        setAllReposFilesData(deepCopyMap);
    };

    const updateMapSubData = (key, subKey, subValue) => {
        const data = allReposFilesData.get(key);
        if (data) {
            const copyData = { ...data };
            copyData[subKey] = subValue;

            updateMap(key, copyData);
        }
    };

    const getRepoFilesData = async (repoId) => {
        try {
            setIsLoading(true);
            setLoadingText("Loading repo's files data ...");

            const dynamicUrl = getUrl(
                `http://${process.env.REACT_APP_NODE_SERVER_HOST}:${process.env.REACT_APP_NODE_SERVER_PORT}/new_alldocs/${repoId}`
            );

            const encodedToken = encodeURIComponent(user.token);
            const response = await fetch(dynamicUrl, {
                method: 'GET', // or 'POST' or other HTTP method
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: encodedToken,
                },
            });

            const data = await response.json();

            const repoData = {
                filesData: data,
                isExpanded: true,
            };

            updateMap(repoId, repoData);

            setIsLoading(false);
            setLoadingText('');
        } catch (error) {
            setIsLoading(false);
            setLoadingText('');
            alert('Error: ', error.message);
        }
    };

    const fetchCurrentTemplate = async (repoId) => {
        try {
            const user = JSON.parse(localStorage.getItem('current'));
            const encodedToken = encodeURIComponent(user.token);
            const response = await fetch(
                getUrl(
                    `http://${process.env.REACT_APP_NODE_SERVER_HOST}:${process.env.REACT_APP_NODE_SERVER_PORT}/getCurrentTemplate/${repoId}`
                ),
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: encodedToken,
                    },
                }
            );

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const result = await response.json();
            console.log('result', result);
            setCurrentTemplate(result);
        } catch (error) {
            console.error('Error fetching current template:', error);
            setCurrentTemplate(null);
        }
    };

    const renderRepos = (repos, processingRepos) => {
        const disableSubAction = processingRepos.length ? true : false;

        const toReturn = repos
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((item, index) => {
                let shared = false;
                let admin = false;

                if (item.shared_type) {
                    shared = true;
                    if (item.shared_type === UserAccessOptions.CHAT) {
                        admin = false;
                    } else {
                        admin = true;
                    }
                }

                let dropDownList = [];

                if (!shared || (shared && admin)) {
                    let userSpecificActionList = [
                        {
                            label: 'Share Repository',
                            icon: faShare,
                            iconColor: 'sky',
                            action: () => {
                                setToShare(item);
                                setViewSharingModal(true);
                            },
                        },
                        {
                            label: 'Delete Repository',
                            icon: faTrash,
                            iconColor: 'red',
                            action: () => {
                                setDeleteMode(true);
                                setToDelete(item);
                            },
                        },
                        {
                            label: 'Extract Data to DB',
                            icon: faDatabase,
                            iconColor: 'blue',
                            action: () => {
                                setSelectedRepoForTemplate(item);
                                setShowSelectTemplateModal(true);
                            },
                            disabled: disableSubAction,
                        },
                        {
                            label: 'Re-run Extraction',
                            icon: faSync,
                            iconColor: 'green',
                            action: () => {
                                fetchCurrentTemplate(item.repoid);
                                setSelectedRepoForReRun(item);
                                setShowReRunExtractionModal(true);
                            },
                            disabled: disableSubAction,
                        },
                    ];
                    if (item.storage_type === RepoType.CLOUD) {
                        const addtocloudtype = [
                            {
                                label: 'Update Repository',
                                icon: faFileCirclePlus,

                                action: () => {
                                    setUpdateCloudRepo(true);
                                    setCloudInfo(item);
                                },
                                disabled: disableSubAction,
                            },
                        ];
                        userSpecificActionList = [
                            ...addtocloudtype,
                            ...userSpecificActionList,
                        ];
                    } else if (
                        item.repotype === RepoType.FILE ||
                        item.repotype === RepoType.VISION
                    ) {
                        const addtofiletype = [
                            {
                                label: 'Add New Files',
                                icon: faFileCirclePlus,
                                iconColor: 'red',
                                action: () => {
                                    setAddFileToRepo(item);
                                    setAddFileMode(true);
                                },
                                disabled: disableSubAction,
                            },
                        ];
                        userSpecificActionList = [
                            ...addtofiletype,
                            ...userSpecificActionList,
                        ];
                    }

                    if (item.repotype === RepoType.POSTGRESS) {
                        const addToList = [
                            {
                                label: 'update schema file',
                                icon: faSync,
                                iconColor: 'lightblue',
                                action: () => {
                                    console.log('update the schema');
                                    handleUpdateSchema(item);
                                },
                            },
                            {
                                label: 'Download schema file',
                                icon: faDownload,
                                iconColor: 'lightblue',
                                action: () => {
                                    console.log('download schema');
                                    downloadSchemaFile(item);
                                },
                            },
                        ];

                        userSpecificActionList = [
                            ...addToList,
                            ...userSpecificActionList,
                        ];
                    }

                    dropDownList = [...userSpecificActionList];
                }

                const commonActionList = [
                    {
                        label: 'View Repository Stats',
                        icon: faChartLine,
                        iconColor: 'lightblue',
                        action: () => {
                            setToViewStatus(item);
                            setViewStatsModal(true);
                            setViewRepodb(false);
                        },
                    }
                ];

                if (
                    item.repotype === RepoType.SQLLITE ||
                    item.repotype === RepoType.POSTGRESS
                ) {
                    const commonActionListdb = [
                        {
                            label: 'View Database Stats',
                            icon: faChartLine,
                            iconColor: 'lightblue',
                            action: () => {
                                setToViewStatus(item);
                                setViewStatsModal(true);
                                setViewRepodb(true);
                            },
                        },
                    ];
                    dropDownList = [...dropDownList, ...commonActionListdb];
                }
                if (item.repotype === RepoType.FILE) {
                    dropDownList = [...dropDownList, ...commonActionList];
                }

                if (
                    developerMode &&
                    (item.repotype === RepoType.FILE ||
                        item.repotype === RepoType.VISION)
                ) {
                    const devModeActionList = [
                        {
                            label: 'Download Diagnostic Data',
                            icon: faDownload,
                            iconColor: 'lightblue',
                            action: async () => {
                                await handleDebuggingFile(item);
                            },
                            disabled: disableSubAction,
                        },
                    ];

                    dropDownList = [...dropDownList, ...devModeActionList];
                }

                const isExpanded = findIfExpanded(item.repoid);

                // Checking if any operation is going on this repo.
                const isProcessingOnRepo = processingRepos.find(
                    (task) => task.repoId === item.repoid
                );

                return (
                    <div className='w-full text-texts-secondary300' key={index}>
                        <button
                            className={`w-full flex items-center justify-between px-3 py-0.5 border border-transparent hover:bg-backgrounds-primary100 hover:border hover:border-backgrounds-primary200 ${
                                workOnRepo === item.repoid
                                    ? 'bg-backgrounds-primary100 text-texts-tertiary hover:border-backgrounds-primary200 shadow-inner'
                                    : isExpanded
                                    ? 'bg-backgrounds-primary100 border-backgrounds-primary200 shadow-inner'
                                    : ''
                            } ${
                                isExpanded
                                    ? 'rounded-t-xl mt-2'
                                    : 'rounded-xl my-2'
                            }`}
                            onClick={(event) => {
                                if (transactionMode) {
                                    setTransactionMode(!transactionMode);
                                }

                                setFileToChat('');
                                if (
                                    workOnRepo !== item.repoid &&
                                    chatting_with_id !== item.repoid
                                ) {
                                    setWorkOnRepo(item.repoid);
                                    chatrepofunction(item);
                                    set_fine_tuned(false);
                                    set_chat_selected(true);
                                    set_chatting_with_id(item.repoid);
                                    if (window.innerWidth < BREAKPOINT_WIDTH) {
                                        props.closeSidebar(); // Call the closeSidebar function passed from navbar.jsx
                                        setWorkOnRepo(item.repoid);
                                        if (path === '/chats')
                                            togglePdfVisibility('reset');
                                    }
                                }

                                event.stopPropagation();
                            }}
                        >
                            <div className='flex items-center justify-center truncate max-w-prose relative'>
                                <FontAwesomeIcon
                                    icon={
                                        item.repotype === RepoType.SQLLITE ||
                                        item.repotype === RepoType.POSTGRESS
                                            ? faDatabase
                                            : item.repotype === RepoType.GLOBAL
                                            ? faFolder
                                            : findIfExpanded(item.repoid)
                                            ? faFolderOpen
                                            : faFolderClosed
                                    }
                                    className={`mr-2 drop-shadow-sm text-icons-primary ${
                                        shared
                                            ? 'text-icons-info'
                                            : 'text-icons-primary'
                                    }`}
                                />
                                {shared && (
                                    <FontAwesomeIcon
                                        icon={faShare}
                                        fontSize={8}
                                        className='absolute left-1 bottom-1.5 drop-shadow-sm text-icons-secondary'
                                    />
                                )}
                                {item.repotype === RepoType.GLOBAL && (
                                    <FontAwesomeIcon
                                        icon={faG}
                                        fontSize={8}
                                        className='absolute left-1 bottom-1.5 drop-shadow-sm text-icons-secondary'
                                    />
                                )}
                                <div className='truncate'>{item.name}</div>
                            </div>
                            <div className='flex items-center justify-center'>
                                {!(
                                    item.repotype === RepoType.POSTGRESS ||
                                    item.repotype === RepoType.SQLLITE ||
                                    item.repotype === RepoType.GLOBAL
                                ) && (
                                    <Button
                                        className='ml-2 text-icons-primary'
                                        onClick={(event) => {
                                            if (!isExpanded) {
                                                getRepoFilesData(item.repoid);
                                            } else {
                                                updateMapSubData(
                                                    item.repoid,
                                                    'isExpanded',
                                                    false
                                                );
                                            }
                                            setIsDropdownOpen(!isDropdownOpen);
                                            event.stopPropagation();
                                        }}
                                        hoverText={
                                            isExpanded
                                                ? 'Collapse and hide list of files in repository'
                                                : 'Expand and show list of files in repository'
                                        }
                                        icon={
                                            isExpanded ? faCaretUp : faCaretDown
                                        }
                                        type={'warning'}
                                    />
                                )}

                                {!(item.repotype === RepoType.GLOBAL) && (
                                    <Dropdown
                                        dropDownList={dropDownList}
                                        icon={
                                            <FontAwesomeIcon
                                                icon={faEllipsisV}
                                            />
                                        }
                                        showButtonText={false}
                                        buttonText={null}
                                        buttonHoverText={
                                            isProcessingOnRepo
                                                ? 'Disabled as a task is being performed on this repo.'
                                                : 'More Repository Actions'
                                        }
                                        additionalButtonAction={() => {
                                            setCloseDropdownSignal(false);
                                        }}
                                        titleText={'Repository Actions'}
                                        buttonClassName={`px-3.5 py-2 ml-2`}
                                        backgroundColor={'white'}
                                        textColor={'green'}
                                        disabled={isProcessingOnRepo}
                                        placement={'right'}
                                        closeSignal={closeDropdownSignal}
                                    />
                                )}
                            </div>
                        </button>
                        {/* {workOnRepo === item.repoid && showIcons && (
                        <div className='flex items-center justify-around m-2 bg-backgrounds-slate p-2 rounded-xl'>

                            <button
                                className='hover:scale-125'
                                onClick={() => {
                                    chatrepofunction(item);
                                    set_fine_tuned(true);
                                    set_chat_selected(false);
                                    set_chatting_with_id(item.repoid);
                                }}
                                hoverText='Chat with fine tune model of repository'
                            >
                                <FontAwesomeIcon
                                    icon={faComment}
                                    color={
                                        chatting_with_id === item.repoid &&
                                        props.fine_tune
                                            ? 'lightblue'
                                            : `inherit`
                                    }
                                />
                            </button>
                        </div>
                    )} */}

                        <FilesListView
                            repo={item}
                            allReposFilesData={allReposFilesData}
                            setWorkOnRepo={setWorkOnRepo}
                            repoId={item.repoid}
                            email={item.email}
                            chatrepofunction={chatrepofunction}
                            folderName={item.name}
                            workOnRepo={workOnRepo}
                            mode={mode}
                            setFileToChat={setFileToChat}
                            fileToChat={fileToChat}
                            isAdmin={isAdmin}
                            closeSidebar={props.closeSidebar}
                            togglePdfVisibility={togglePdfVisibility}
                        />
                    </div>
                );
            });
        return toReturn;
    };

    const processingRepos = props.processingRepos ? props.processingRepos : [];

    const renderGlobalRepos = renderRepos(globalRepos, processingRepos);
    const renderNormalRepos = renderRepos(repoDetails, processingRepos);
    const renderSharedRepos = renderRepos(sharedRepos, processingRepos);

    const truncateText = (text, maxLength) => {
        return text.length > maxLength
            ? `${text.substring(0, maxLength)}...`
            : text;
    };

    return (
        <div
            className={`z-0 h-full w-full transition-all duration-700 ease-in-out bg-transparent p-1 m-0 rounded-xl`}
        >
            <ReflexContainer orientation='horizontal'>
                <ReflexElement
                    className='header border-b-2 border-b-backgrounds-white'
                    style={{
                        minHeight: '8rem',
                        height: '8rem',
                        maxHeight: '8rem',
                    }}
                >
                    <div className='pane-content w-full h-full pb-2 px-1'>
                        <div className='w-full h-full flex flex-col justify-between items-center text-texts-secondary'>
                            <Brand />

                            <div className='w-full flex items-center justify-between'>
                                <div className='w-full flex items-center truncate'>
                                    <FontAwesomeIcon
                                        icon={faFolder}
                                        className='mr-2 drop-shadow-sm text-icons-primary'
                                    />
                                    <div className='font-semibold truncate'>
                                        Repositories
                                    </div>
                                </div>
                                <div className='flex items-center justify-center'>
                                    <Button
                                        icon={faFolderPlus}
                                        className={
                                            'mr-2 w-9 h-9 text-icons-primary'
                                        }
                                        onClick={() => {
                                            setAddNewMode(true);
                                        }}
                                        disabled={processingRepos.length}
                                        hoverText={
                                            processingRepos.length
                                                ? 'Create new repo is disabled as a heavy task is under progress'
                                                : 'Add new repository'
                                        }
                                        type={'success'}
                                    />

                                    <Button
                                        icon={faCaretLeft}
                                        className={'w-9 h-9 text-icons-primary'}
                                        onClick={() => {
                                            if (
                                                window.innerWidth >
                                                BREAKPOINT_WIDTH
                                            ) {
                                                props.setIsRepoViewExpanded(
                                                    false
                                                );
                                            } else {
                                                props.closeSidebar();
                                            }
                                        }}
                                        hoverText={
                                            'Collapse and hide repositories view'
                                        }
                                        type={'warning'}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </ReflexElement>

                <ReflexElement flex={1}>
                    <div className='w-full h-full pane-content p-1'>
                        {isLoading && (
                            <div className='w-full h-full flex justify-center items-center break-all'>
                                <LoadingView
                                    loadingText={loadingText}
                                    inLine={false}
                                />
                            </div>
                        )}
                        {!isLoading && (
                            <div
                                className='w-full h-full overflow-auto'
                                onScroll={() => {
                                    toggleDropdownSignal();
                                }}
                            >
                                <div
                                    className={
                                        globalRepos.length
                                            ? 'border-b border-b-white'
                                            : null
                                    }
                                >
                                    {renderGlobalRepos}
                                </div>
                                <div>{renderNormalRepos}</div>
                                <div
                                    className={
                                        sharedRepos.length
                                            ? 'border-b border-b-white'
                                            : null
                                    }
                                >
                                    {renderSharedRepos}
                                </div>
                            </div>
                        )}
                    </div>
                </ReflexElement>

                <ReflexElement
                    className='footer border-t-2 border-t-backgrounds-white'
                    style={{
                        minHeight: '9.5rem',
                        height: '9.5rem',
                        maxHeight: '9.5rem',
                    }}
                >
                    <div className='pane-content w-full h-full'>
                        <UserDetailAndAction />
                    </div>
                </ReflexElement>
            </ReflexContainer>

            {addNewMode && (
                <Modal
                    title={'Add New Repository'}
                    titleIcon={faFolderPlus}
                    iconColor={'lightblue'}
                    onClose={() => setAddNewMode(false)}
                >
                    <AddNewRepo
                        setAddNewMode={setAddNewMode}
                        getAllReposData={getAllReposData}
                        socket={props.socket}
                        setViewNotifications={props.setViewNotifications}
                        closeSidebar={props.closeSidebar}
                        isAdmin={isAdmin}
                    />
                </Modal>
            )}

            {addFileMode && (
                <Modal
                    title={'Add New Files'}
                    titleIcon={faFileCirclePlus}
                    iconColor={'lightblue'}
                    onClose={() => {
                        setAddFileMode(false);
                    }}
                >
                    <AddFile
                        setAddFileMode={setAddFileMode}
                        foldername={addFileToRepo.name}
                        repoid={addFileToRepo.repoid}
                        getRepoFilesData={getRepoFilesData}
                        socket={props.socket}
                        setViewNotifications={props.setViewNotifications}
                        closeSidebar={props.closeSidebar}
                        mail={addFileToRepo.email}
                    />
                </Modal>
            )}

            {updateCloudRepo && (
                <ConfirmModal
                    modalText={`Do you want to update your Cloud Repository '${cloudInfo.name}'`}
                    onConfirm={() => {
                        updateCloud(cloudInfo);

                        setUpdateCloudRepo(false);
                    }}
                    onCancel={() => {
                        setUpdateCloudRepo(false);
                    }}
                />
            )}

            {viewSharingModal && (
                <Modal
                    title={`Share '${truncateText(toShare.name, 20)}'`}
                    titleIcon={faShare}
                    iconColor={'sky'}
                    onClose={() => {
                        setViewSharingModal(false);
                    }}
                >
                    <ShareRepository
                        user={user}
                        repositoryData={toShare}
                        setViewSharingModal={setViewSharingModal}
                    />
                </Modal>
            )}

            {viewStatsModal && (
                <Modal
                    title={`${toViewStats.name} Stats`}
                    titleIcon={faChartLine}
                    iconColor={'lightblue'}
                    onClose={() => {
                        setViewStatsModal(false);
                    }}
                >
                    {viewRepodb ? (
                        <RepoStatsDB
                            repoName={toViewStats.name}
                            email={toViewStats.email}
                        />
                    ) : (
                        <RepositoryStats
                            repository={toViewStats}
                            filesListData={allReposFilesData.get(
                                toViewStats.repoid
                            )}
                            mode={Mode.CHAT}
                        />
                    )}
                </Modal>
            )}

            {deleteMode && (
                <ConfirmModal
                    modalText={`Delete '${toDelete.name}' repository?`}
                    onConfirm={async () => await finalizeDelete()}
                    onCancel={() => setDeleteMode(false)}
                />
            )}

            {showSelectTemplateModal && (
                <Modal
                    title={`Extract '${truncateText(
                        selectedRepoForTemplate.name,
                        20
                    )}' data into db`}
                    titleIcon={faDatabase}
                    iconColor={'blue'}
                    onClose={() => {
                        setShowSelectTemplateModal(false);
                        setSelectedRepoForTemplate(null);
                    }}
                >
                    <ExtractData
                        templateList={templateList}
                        repoName={selectedRepoForTemplate.name}
                        repoId={selectedRepoForTemplate.repoid}
                        email={selectedRepoForTemplate.email}
                        socket={props.socket}
                        currentTemplate={currentTemplate}
                        getAllReposData={getAllReposData}
                        onClose={() => {
                            setShowSelectTemplateModal(false);
                            setSelectedRepoForTemplate(null);
                        }}
                        fetchDownloadList={fetchDownloadList}
                    />
                </Modal>
            )}

            {showReRunExtractionModal && (
                <Modal
                    title={`Re-run Extraction for '${truncateText(
                        selectedRepoForReRun.name,
                        10
                    )}'`}
                    titleIcon={faSync}
                    iconColor={'green'}
                    onClose={() => {
                        setShowReRunExtractionModal(false);
                        setSelectedRepoForReRun(null);
                    }}
                >
                    {currentTemplate ? (
                        <ExtractData
                            templateList={templateList}
                            currentTemplate={currentTemplate}
                            repoName={selectedRepoForReRun.name}
                            repoId={selectedRepoForReRun.repoid}
                            email={selectedRepoForReRun.email}
                            socket={props.socket}
                            getAllReposData={getAllReposData}
                            onClose={() => {
                                setShowReRunExtractionModal(false);
                                setSelectedRepoForReRun(null);
                            }}
                            fetchDownloadList={fetchDownloadList}
                            isReRun={true}
                        />
                    ) : (
                        <div className='p-4 text-center'>
                            No template has been selected for this repository.
                            Please run an extraction with a template first.
                        </div>
                    )}
                </Modal>
            )}
        </div>
    );
};

export default Sidebar;
