import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faExclamationTriangle,
    faPlus,
} from '@fortawesome/free-solid-svg-icons';
import { Button } from './reusableComponents';
import { useAuth } from '../context/AuthContext';
import axios from 'axios';
import { loadPaymentScript } from '../utils/loadPaymentScript';
import { Currency } from '../constants/currency';
import { getUrl } from './pdfDisplay/utils';
const Addcredits = (props) => {
    const [amount, setAmount] = useState(0);
    const [error, setError] = useState('');
    const { user } = useAuth();

    const static_amount = Currency[user.currency]?.suggestedAmount || [];
    const symbol = Currency[user.currency]?.symbol || '';

    const handleCheckout = async () => {
        try {
            if (amount) {
                if (String(amount).includes('.')) {
                    setError('Please enter amount without decimal places.');
                    return;
                }

                if (
                    user.currency === 'USD' &&
                    (parseInt(amount) < 10 || parseInt(amount) > 500)
                ) {
                    setError('Please enter a valid amount between 10 to 500.');
                    return;
                } else if (
                    user.currency === 'INR' &&
                    (parseInt(amount) < 500 || parseInt(amount) > 50000)
                ) {
                    setError(
                        'Please enter a valid amount between 500 to 50000.'
                    );
                    return;
                }
            } else if (!amount) {
                setError('Please enter a valid amount.');
                return;
            }

            const dynamicUrl = getUrl(`http://${process.env.REACT_APP_NODE_SERVER_HOST}:${process.env.REACT_APP_NODE_SERVER_PORT}/create-checkout-session`);
            const authToken = encodeURIComponent(user.token);

            const response = await axios.post(
                dynamicUrl,
                {
                    amount: amount,
                    currency: user.currency,
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: authToken,
                    },
                }
            );
            if (response) {
                let data = {
                    amount: response.data.amount,
                    order_id: response.data.id,
                    currency: response.data.currency,
                };
                loadPaymentScript(user, data);
            }
        } catch (err) {
            console.log('Error creating checkout session', error);
        }
    };

    return (
        <div className='w-[90vw] min-w-[90px] md:w-[60vw] md:min-w-[500px] flex-col px-6 py-6 rounded-xl bg-slate-100 '>
            <div className='relative flex flex-col  w-full text-texts-secondary'>
                <div className='flex flex-row mb-3 justify-center items-center mb-3'>
                    <div className='text-texts-secondary mr-4 w-40'>
                        Enter Amount ({symbol})
                    </div>
                    <input
                        className='rounded-xl border p-2.5 w-full hover:outline-none focus:outline-backgrounds-primary300 hover:border-backgrounds-primary300 shadow-sm'
                        type='number'
                        placeholder='Enter Amount'
                        value={amount}
                        onChange={(e) => {
                            setError('');
                            setAmount(e.target.value);
                        }}
                    />
                </div>
                <hr className='mt-5 mb-5' />
                <div className='flex flex-col mb-3 justify-center items-center mb-3'>
                    <div className='mb-5'>Select Amount</div>
                    <div className='flex justify-between w-10/12'>
                        {static_amount.map((elem) => {
                            return (
                                <Button
                                    className={`h-10  w-40 flex-end`}
                                    hoverText={`${symbol} ${elem.toFixed(1)}`}
                                    text={`${symbol} ${elem.toFixed(1)}`}
                                    // icon={faWallet}
                                    type={'success'}
                                    onClick={() => {
                                        setAmount(elem);
                                    }}
                                />
                            );
                        })}
                    </div>
                    {error && (
                        <div className='flex justify-center items-center text-texts-red400 mt-3'>
                            <FontAwesomeIcon
                                icon={faExclamationTriangle}
                                className='mr-2'
                            />
                            {error}
                        </div>
                    )}
                    <div className='w-full flex justify-end'>
                        <Button
                            className={`h-10  w-40 flex-end mt-10`}
                            hoverText={'Add Credits'}
                            text={`Add Credits`}
                            icon={faPlus}
                            type={'success'}
                            onClick={handleCheckout}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Addcredits;
