import React from 'react';

export const Input = ({
    value,
    placeholder,
    pattern,
    type,
    onChange,
    className,
    disabled,
    onInvalid,
    onInput,
    maxLength,
    hoverText,
    autoFocus,
    onKeyDown,
}) => {
    return (
        <>
            <style>
                {`input[type="password"]::-ms-reveal {
                    display: none;
                }`}
            </style>
            <input
                className={`${
                    !disabled
                        ? 'bg-backgrounds-white text-texts-secondary'
                        : 'bg-backgrounds-slate text-texts-secondary300'
                } w-full p-2.5 rounded-xl hover:outline-none focus:outline-backgrounds-primary200 border hover:border-backgrounds-primary300 ${
                    className ? className : ''
                }`}
                type={type}
                placeholder={placeholder}
                value={value}
                onInvalid={onInvalid}
                onInput={onInput}
                pattern={pattern}
                onChange={onChange}
                disabled={disabled}
                maxlength={`${maxLength}`}
                title={hoverText}
                autoFocus={autoFocus ? autoFocus : false}
                onKeyDown={onKeyDown ? onKeyDown : () => {}}
            />
        </>
    );
};
