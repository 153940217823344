import React from 'react';
import {
    faCheckCircle,
    faCircleXmark,
} from '@fortawesome/free-solid-svg-icons';
import { Button } from './button';

export const ConfirmModal = ({ modalText, onConfirm, onCancel }) => {
    return (
        <div className='relative'>
            <div className='fixed top-0 left-0 min-h-screen w-screen flex justify-center items-center bg-backgrounds-slate bg-opacity-20 z-50 border rounded-xl backdrop-filter backdrop-blur-sm'>
                <div className='w-full sm:w-auto md:w-auto lg:w-auto xl:w-auto relative bg-backgrounds-slate p-4 rounded-xl shadow-lg flex-col items-center border sm:mx-2 max-w-full'>
                    <div className='flex items-center justify-start w-full mb-4 p-4 border rounded-xl bg-backgrounds-white h-auto'>
                        <div className='text-xl font-semibold text-texts-tertiary break-all'>
                            {modalText}
                        </div>
                    </div>

                    <div className='flex items-center justify-between w-full min-w-[300px]'>
                        <Button
                            className='w-1/3 min-w-[120px]'
                            type={'danger'}
                            onClick={() => {
                                onCancel();
                            }}
                            icon={faCircleXmark}
                            text={'Cancel'}
                            hoverText={'Cancel the above action'}
                        />
                        <Button
                            className='w-1/3 min-w-[120px]'
                            type={'success'}
                            onClick={() => {
                                onConfirm();
                            }}
                            icon={faCheckCircle}
                            text={'Confirm'}
                            hoverText={'Accept the above action'}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
