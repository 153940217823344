import React, { forwardRef } from 'react';
import { LandingPageButton } from '../../components/reusableComponents';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { getUrl } from '../../components/pdfDisplay/utils';
import {
    documentSizeAllowed,
    enterprisePricingINR,
    enterprisePricingUSD,
    freeCreditsINR,
    freeCreditsUSD,
} from '../../Constants';
const Purchases = forwardRef((props, ref) => {
    const handleClick = (url) => {
        if (url.charAt(0) === '/') {
            // Ensure environment variables are set correctly
            const host = process.env.REACT_APP_HOST;
            const port = process.env.REACT_APP_PORT;
            const useSecuredConnection =
                process.env.REACT_APP_USE_SECURED_CONNECTION;
            if (useSecuredConnection) {
                window.open(getUrl(`http://${host}${url}`), '_self');
            } else {
                // Construct the full URL with the path
                window.open(getUrl(`http://${host}:${port}${url}`), '_self');
            }
        } else {
            window.location.href = url;
        }
    };

    return (
        <div
            ref={ref}
            id='targetComponent'
            className='mt-6 mx-0 md:mx-8 lg:mx-20 rounded-lg shadow-md p-8 bg-backgrounds-secondaryChat'
        >
            <h1 className='font-bold text-3xl text-texts-secondary mb-8 text-center'>
                Pick the plan fitting your budget
            </h1>
            <div className='grid grid-cols-1 gap-0 md:grid-cols-2 lg:grid-cols-2'>
                {/* First Purchase Card */}
                <div className='w-full max-w-md mx-auto rounded-lg overflow-hidden shadow-lg bg-backgrounds-primary100'>
                    <div className='px-8 pt-6'>
                        <div className='font-bold text-2xl mb-4 pt-6'>
                            Free Tier
                        </div>
                        <p className='text-texts-secondary text-lg mb-4'>
                            Free upto
                            <b className='ml-1 mr-1'>{`$${freeCreditsUSD} (₹${freeCreditsINR})`}</b>
                            of usage for individuals.
                        </p>
                    </div>
                    <div className='px-8 pb-6 mb-6'>
                        <ul className='text-texts-secondary300 text-md space-y-2'>
                            <li className='flex items-center'>
                                <svg
                                    className='w-6 h-6 mr-3'
                                    fill='none'
                                    stroke='currentColor'
                                    viewBox='0 0 24 24'
                                    xmlns='http://www.w3.org/2000/svg'
                                >
                                    <path
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                        strokeWidth='2'
                                        d='M5 13l4 4L19 7'
                                    ></path>
                                </svg>
                                {`Upto $${freeCreditsUSD} (₹${freeCreditsINR}) credits free for all the
                                operations`}
                            </li>
                            <li className='flex items-center'>
                                <svg
                                    className='w-6 h-6 mr-3'
                                    fill='none'
                                    stroke='currentColor'
                                    viewBox='0 0 24 24'
                                    xmlns='http://www.w3.org/2000/svg'
                                >
                                    <path
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                        strokeWidth='2'
                                        d='M5 13l4 4L19 7'
                                    ></path>
                                </svg>
                                {`Chat with PDF documents upto ${documentSizeAllowed}`}
                            </li>
                            <li className='flex items-center'>
                                <svg
                                    className='w-6 h-6 mr-3'
                                    fill='none'
                                    stroke='currentColor'
                                    viewBox='0 0 24 24'
                                    xmlns='http://www.w3.org/2000/svg'
                                >
                                    <path
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                        strokeWidth='2'
                                        d='M5 13l4 4L19 7'
                                    ></path>
                                </svg>
                                Extract data and create beautiful reports
                            </li>

                            <li className='flex items-center'>
                                <svg
                                    className='w-6 h-6 mr-3'
                                    fill='none'
                                    stroke='currentColor'
                                    viewBox='0 0 24 24'
                                    xmlns='http://www.w3.org/2000/svg'
                                >
                                    <path
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                        strokeWidth='2'
                                        d='M5 13l4 4L19 7'
                                    ></path>
                                </svg>
                                Buy additional credits using UPI, Credit card{' '}
                                <br />
                                and Debit card.
                            </li>
                            <br />
                        </ul>
                    </div>
                    <div className='px-8 pb-6 flex justify-center'>
                        <LandingPageButton
                            className='bg-backgrounds-primary300 font-semibold border-white border-2 text-white px-6 py-3 rounded-full flex items-center justify-center hover:bg-gradient-to-r hover:from-backgrounds-primary300 hover:via-backgrounds-primary300 hover:to-primary200 hover:border-white hover:text-white'
                            onClick={() => handleClick('/signup')}
                            text='Start for free'
                            icon={faArrowRight}
                        />
                    </div>
                </div>

                {/* Second Purchase Card - Blurred */}
                {/* <div className='max-w-lg mx-auto rounded-lg overflow-hidden shadow-lg bg-backgrounds-primary100 relative'>
                    <div className='px-8 pt-6 filter blur-xl'>
                        <div className='font-bold text-2xl mb-4'>
                            Premium
                            <br />
                            $29
                        </div>
                        <p className='text-texts-secondary text-lg mb-4'>
                            per user/month billed yearly <br /> <b>$39</b>{' '}
                            billed monthly
                        </p>
                    </div>
                    <div className='px-8 pb-6 mb-6 filter blur-xl'>
                        <ul className='text-texts-secondary300 text-md space-y-2'>
                            <li className='flex items-center'>
                                <svg
                                    className='w-6 h-6 mr-3'
                                    fill='none'
                                    stroke='currentColor'
                                    viewBox='0 0 24 24'
                                    xmlns='http://www.w3.org/2000/svg'
                                >
                                    <path
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                        strokeWidth='2'
                                        d='M5 13l4 4L19 7'
                                    ></path>
                                </svg>
                                Upload unlimited repository
                            </li>
                            <li className='flex items-center'>
                                <svg
                                    className='w-6 h-6 mr-3'
                                    fill='none'
                                    stroke='currentColor'
                                    viewBox='0 0 24 24'
                                    xmlns='http://www.w3.org/2000/svg'
                                >
                                    <path
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                        strokeWidth='2'
                                        d='M5 13l4 4L19 7'
                                    ></path>
                                </svg>
                                5GB of space
                            </li>
                            <li className='flex items-center'>
                                <svg
                                    className='w-6 h-6 mr-3'
                                    fill='none'
                                    stroke='currentColor'
                                    viewBox='0 0 24 24'
                                    xmlns='http://www.w3.org/2000/svg'
                                >
                                    <path
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                        strokeWidth='2'
                                        d='M5 13l4 4L19 7'
                                    ></path>
                                </svg>
                                Buy Never expiring Credits
                            </li>
                            <li className='flex items-center'>
                                <svg
                                    className='w-6 h-6 mr-3'
                                    fill='none'
                                    stroke='currentColor'
                                    viewBox='0 0 24 24'
                                    xmlns='http://www.w3.org/2000/svg'
                                >
                                    <path
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                        strokeWidth='2'
                                        d='M5 13l4 4L19 7'
                                    ></path>
                                </svg>
                                Smart Notifications
                                <br />
                                <br />
                                <br />
                            </li>
                        </ul>
                    </div>
                    <div className='px-8 pb-6 flex justify-center'>
                        <button
                            className='bg-backgrounds-primary300 font-semibold border-white border-2 text-white px-6 py-3 rounded-full flex items-center justify-center hover:bg-gradient-to-r hover:from-backgrounds-primary300 hover:via-backgrounds-primary300 hover:to-primary200 hover:border-white hover:text-white'
                            onClick={() => handleClick('/signup')}
                            disabled
                        >
                            Get Premium (Coming Soon)
                            <svg
                                className='w-6 h-6 ml-3'
                                fill='none'
                                stroke='currentColor'
                                viewBox='0 0 24 24'
                                xmlns='http://www.w3.org/2000/svg'
                            >
                                <path
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                    strokeWidth='2'
                                    d='M17 8l4 4m0 0l-4 4m4-4H3'
                                ></path>
                            </svg>
                        </button>
                    </div>
                </div> */}

                {/* Third Purchase Card - Blurred */}
                {/* <div className='max-w-lg mx-auto rounded-lg overflow-hidden shadow-lg bg-backgrounds-primary100'>
                    <div className='px-8 pt-6 filter blur-xl'>
                        <div className='font-bold text-2xl mb-4'>
                            Enterprise
                            <br />
                            $35
                        </div>
                        <p className='text-texts-secondary text-lg mb-4'>
                            per user/month billed yearly <br /> <b>$45</b>{' '}
                            billed monthly
                        </p>
                    </div>
                    <div className='px-8 pb-6 mb-6 filter blur-xl'>
                        <ul className='text-texts-secondary300 text-md space-y-2'>
                            <li className='flex items-center'>
                                <svg
                                    className='w-6 h-6 mr-3'
                                    fill='none'
                                    stroke='currentColor'
                                    viewBox='0 0 24 24'
                                    xmlns='http://www.w3.org/2000/svg'
                                >
                                    <path
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                        strokeWidth='2'
                                        d='M5 13l4 4L19 7'
                                    ></path>
                                </svg>
                                Upload unlimited repositories
                            </li>

                            <li className='flex items-center'>
                                <svg
                                    className='w-6 h-6 mr-3'
                                    fill='none'
                                    stroke='currentColor'
                                    viewBox='0 0 24 24'
                                    xmlns='http://www.w3.org/2000/svg'
                                >
                                    <path
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                        strokeWidth='2'
                                        d='M5 13l4 4L19 7'
                                    ></path>
                                </svg>
                                Buy credits
                            </li>
                            <li className='flex items-center'>
                                <svg
                                    className='w-6 h-6 mr-3'
                                    fill='none'
                                    stroke='currentColor'
                                    viewBox='0 0 24 24'
                                    xmlns='http://www.w3.org/2000/svg'
                                >
                                    <path
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                        strokeWidth='2'
                                        d='M5 13l4 4L19 7'
                                    ></path>
                                </svg>
                                Pay as you Go plan
                                <br />
                                <br />
                                <br />
                            </li>
                        </ul>
                    </div>
                    <div className='px-8 pb-6 flex justify-center'>
                        <button
                            className='bg-backgrounds-primary300 font-semibold border-white border-2 text-white px-6 py-3 rounded-full flex items-center justify-center hover:bg-gradient-to-r hover:from-backgrounds-primary300 hover:via-backgrounds-primary300 hover:to-primary200 hover:border-white hover:text-white'
                            onClick={() => handleClick('/signup')}
                            disabled
                        >
                            Get Enterprise (Coming Soon)
                            <svg
                                className='w-6 h-6 ml-3'
                                fill='none'
                                stroke='currentColor'
                                viewBox='0 0 24 24'
                                xmlns='http://www.w3.org/2000/svg'
                            >
                                <path
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                    strokeWidth='2'
                                    d='M17 8l4 4m0 0l-4 4m4-4H3'
                                ></path>
                            </svg>
                        </button>
                    </div>
                </div> */}

                {/* Fourth Purchase Card - Blurred */}
                <div className='w-full max-w-md mx-auto rounded-lg overflow-hidden shadow-lg bg-backgrounds-primary100'>
                    <div className='px-8 pt-10'>
                        <div className='font-bold text-2xl '>
                            Enterprise Tier
                            <br />
                        </div>
                        <p className='text-texts-secondary text-lg mb-4'>
                            <br /> billed
                            <b className='ml-1 mr-1'>{`$${enterprisePricingUSD} (₹${enterprisePricingINR})`}</b>
                            per user per month
                        </p>
                    </div>
                    <div className='px-8 pb-6 mb-6 '>
                        <ul className='text-texts-secondary300 text-md space-y-2'>
                            <li className='flex items-center'>
                                <svg
                                    className='w-6 h-6 mr-3'
                                    fill='none'
                                    stroke='currentColor'
                                    viewBox='0 0 24 24'
                                    xmlns='http://www.w3.org/2000/svg'
                                >
                                    <path
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                        strokeWidth='2'
                                        d='M5 13l4 4L19 7'
                                    ></path>
                                </svg>
                                Get an Enterprise usage license
                            </li>
                            {/* <li className='flex items-center'>
                                <svg
                                    className='w-6 h-6 mr-3'
                                    fill='none'
                                    stroke='currentColor'
                                    viewBox='0 0 24 24'
                                    xmlns='http://www.w3.org/2000/svg'
                                >
                                    <path
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                        strokeWidth='2'
                                        d='M5 13l4 4L19 7'
                                    ></path>
                                </svg>
                                Own and protect your data
                            </li> */}
                            <li className='flex items-center'>
                                <svg
                                    className='w-6 h-6 mr-3'
                                    fill='none'
                                    stroke='currentColor'
                                    viewBox='0 0 24 24'
                                    xmlns='http://www.w3.org/2000/svg'
                                >
                                    <path
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                        strokeWidth='2'
                                        d='M5 13l4 4L19 7'
                                    ></path>
                                </svg>
                                Customized according to requirements
                            </li>
                            <li className='flex items-center'>
                                <svg
                                    className='w-6 h-6 mr-3'
                                    fill='none'
                                    stroke='currentColor'
                                    viewBox='0 0 24 24'
                                    xmlns='http://www.w3.org/2000/svg'
                                >
                                    <path
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                        strokeWidth='2'
                                        d='M5 13l4 4L19 7'
                                    ></path>
                                </svg>
                                Make it an in-house product
                            </li>
                            <li className='flex items-center'>
                                <svg
                                    className='w-6 h-6 mr-3'
                                    fill='none'
                                    stroke='currentColor'
                                    viewBox='0 0 24 24'
                                    xmlns='http://www.w3.org/2000/svg'
                                >
                                    <path
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                        strokeWidth='2'
                                        d='M5 13l4 4L19 7'
                                    ></path>
                                </svg>
                                Have full ownership and control
                                <br /> of your data
                            </li>
                        </ul>
                    </div>
                    <div className='px-8 pb-6 flex justify-center'>
                        <LandingPageButton
                            className='bg-backgrounds-primary300 font-semibold border-white border-2 text-white px-6 py-3 rounded-full flex items-center justify-center hover:bg-gradient-to-r hover:from-backgrounds-primary300 hover:via-backgrounds-primary300 hover:to-primary200 hover:border-white hover:text-white'
                            onClick={() => handleClick('/contactUs')}
                            text='Contact Us'
                            icon={faArrowRight}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
});

export default Purchases;
